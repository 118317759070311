<template>
  <v-container class="pa-0 gift-cards-page">
    <div v-if="cards.length > 0">
      <div class="pa-2 mb-2">
        <h4 class="text-uppercase">{{ $t("giftCard.card.title") }}</h4>
        <div>{{ $t("giftCard.card.description") }}</div>
      </div>
      <v-row class="grey lighten-1 pa-2 mb-6">
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="(card, index) in cards"
          :key="'card-' + index"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="gift-card" @click="showDetails(card)" v-ripple>
            <div
              class="top-part pa-3"
              :class="card.disponibile == '0' ? 'expired' : 'active'"
            >
              {{
                $t("giftCard.card.valid", {
                  startDate: $dayjs(card.attiva).format("DD/MM/YYYY"),
                  endDate: $dayjs(card.scadenza).format("DD/MM/YYYY")
                })
              }}
            </div>
            <div class="d-flex justify-space-between align-center px-2 py-1">
              <div class="text-body-0">
                {{ $t("giftCard.card.availableBalance") }}
              </div>
              <div class="text-h4">
                {{
                  $n(parseFloat(card.disponibile.replace(",", ".")), "currency")
                }}
              </div>
            </div>
          </div>
          <div class="mt-2 w-100 d-flex justify-space-between flex-wrap">
            <v-btn
              text
              color="default"
              depressed
              rounded
              small
              class="text-decoration-underline px-1"
              @click="openHistory(card.qrCode)"
              >{{ $t("giftCard.card.history") }}</v-btn
            >
            <v-btn
              text
              color="default"
              depressed
              rounded
              small
              class="text-decoration-underline px-1"
              @click="confirmRemoveCard(card.qrCode)"
              >{{ $t("giftCard.card.remove") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="pa-2">
      <h4 class="text-uppercase">{{ $t("giftCard.title") }}</h4>
      <div :class="cards.length < 1 ? 'text-body-0' : ''">
        {{ $t("giftCard.description") }}
      </div>
      <v-btn
        color="primary"
        depressed
        rounded
        x-large
        block
        class="my-6"
        @click="scanCard"
        :loading="loading"
        >{{ $t("giftCard.addCard") }}</v-btn
      >
    </div>
  </v-container>
</template>
<style lang="scss">
.gift-cards-page {
  .gift-card {
    width: 100%;
    max-width: 385px;
    aspect-ratio: 1.632;
    background-color: $white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .top-part {
      color: $white;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      flex-grow: 1;
    }
    .top-part.active {
      background-image: url("/img_layout/giftCard/card.png");
    }
    .top-part.expired {
      background-image: url("/img_layout/giftCard/card_non_attiva.png");
    }
    .text-h4 {
      font-size: 18px !important;
    }
  }
}
</style>
<script>
import AbbondanzaGiftCardService from "@/service/abbondanzaGiftCardService";
import GiftCardDetails from "@/components/gift/GiftCardDetails.vue";
import GiftCardReport from "@/components/gift/GiftCardReport.vue";
export default {
  name: "GiftCardHome",
  data() {
    return {
      cards: [],
      loading: false
    };
  },
  methods: {
    reload() {
      AbbondanzaGiftCardService.getGiftCardlist().then(data => {
        if (data.values) {
          this.cards = data.values;
        } else {
          this.cards = [];
        }
      });
    },
    async showDetails(card) {
      let res = await this.$dialog.show(GiftCardDetails, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        card: card,
        width: 500
      });
      if (res) {
        console.log(res);
      }
      this.reload();
    },
    scanCard() {
      if (window.cordova && window.cordova.plugins.barcodeScanner) {
        try {
          this.loading = true;
          // eslint-disable-next-line no-undef
          cordova.plugins.barcodeScanner.scan(
            result => {
              if (result) {
                this.loading = false;
                const barcode =
                  result.text.length === 13 ? "0" + result.text : result.text;
                this.addCard(barcode);
              }
            },
            error => {
              this.loading = false;
              console.error("Scanning failed", error);
              if (
                error
                  .toString()
                  .toLowerCase()
                  .includes("illegal access")
              ) {
                this.$dialog.confirm({
                  text: `<p class='text-center font-weight-semibold pre-wrap py-3'>${this.$t(
                    "message.cameraPermission"
                  )}</p>`,
                  class: "",
                  actions: {}
                });
              }
            },
            {
              showFlipCameraButton: true,
              showTorchButton: true,
              resultDisplayDuration: 500,
              formats: this.formats || "QR_CODE,CODE_128"
            }
          );
        } catch (err) {
          console.log(err);
        }
      } else {
        console.log("Barcode Scanner plugin not present");
      }
    },
    async addCard(qrCode) {
      await AbbondanzaGiftCardService.addCard(qrCode);
      this.reload();
    },
    async removeCard(qrCode) {
      await AbbondanzaGiftCardService.removeCard(qrCode);
      this.reload();
    },
    async confirmRemoveCard(qrCode) {
      let title = this.$t("giftCard.card.removeDialogTitle") + " ";
      let res = await this.$dialog.confirm({
        text: `<h2 class='primary--text text-center'>${title}</h2>`,
        class: "confirm-coupon-activation",
        actions: {
          false: {
            color: "var(--v-primary-base)",
            rounded: true,
            text: this.$t("common.no")
          },
          true: {
            rounded: true,
            text: this.$t("common.yes")
          }
        }
      });
      if (res) {
        this.removeCard(qrCode);
      }
    },
    async openHistory(code) {
      let res = await this.$dialog.show(GiftCardReport, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        cardCode: code,
        width: 500
      });
      if (res) {
        console.log(res);
      }
      this.reload();
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
